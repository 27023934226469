import React from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { HpLinkIcon } from 'src/styles/global';
import { IconChevronRight } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TListItemTrailingAreaProps = {
  status: any;
  url: string;
};
const ListItemTrailingArea = (props: TListItemTrailingAreaProps) => {
  const { navigation, isWex, routes } = useShellRootContext();
  const handleNavigate = () => {
    if (isWex) {
      if (window.location.pathname.startsWith('/ui/fleet-management')) {
        //Old WXP Side Menu
        if (props.url == 'proxy')
          routes?.navigate?.('printProxies', {}, false, false, 'fleetLayout');
      } else {
        //new WXP Side Menu
        if (props.url == 'proxy')
          routes?.navigate?.('printerProxies', {}, false, false, 'devicesLayout');
      }
    } else {
      navigation.push(props.url);
    }
  };

  if (!props.url) {
    return <></>;
  }

  return (
    <Flexbox>
      {props.status}
      <LinkArea
        data-testid={`connectivity-type-section-handle-navigate`}
        onClick={() => handleNavigate()}
      >
        <HpLinkIcon data-testid="list-item-trailing-area-hp-link-icon" isWex={isWex}>
          {t('view_details')}
        </HpLinkIcon>
        <IconArea>
          <IconChevronRight color={isWex ? 'darkNavy7' : 'hpBlue7'} />
        </IconArea>
      </LinkArea>
    </Flexbox>
  );
};

const Flexbox = styled.div`
  display: flex;
  width: 280px;
  justify-content: space-between;
`;

const LinkArea = styled.div`
  display: flex;
  padding-left: 20px;
`;

const IconArea = styled.label`
  cursor: pointer;
  margin-left: 3px;
`;
export default ListItemTrailingArea;
