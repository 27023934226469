import { objHasKeys, refined, refinedArray, refinedDate } from 'src/utils/commonMethods';

export const refinedFetchDeviceInfo = (data) => {
  const refinedSolutionMetadataForProxy = (data: any) => {
    if (data === undefined || data === null) {
      return [];
    }

    return Object.values(data)
      .filter(
        (obj: { fleetProxyId: string }) =>
          obj.fleetProxyId !== undefined && obj.fleetProxyId !== null,
      )
      .map((obj: { fleetProxyId: string }) => obj.fleetProxyId);
  };

  const refinedSolutionMetadata = (data: any) => {
    const emptySolutionMetadata = {
      result: 'unknown',
    };
    if (data === null || data === undefined || Object.values(data).length === 0) {
      return [emptySolutionMetadata];
    }
    let objNullChecker = false;
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) {
        objNullChecker = true;
      }
    }
    if (objNullChecker) {
      return [emptySolutionMetadata];
    }

    return Object.values(data)
      .filter((obj: { policyId: string }) => obj.policyId !== undefined && obj.policyId !== null)
      .map(
        (obj: {
          state: string;
          result: string;
          policyId: string;
          lastAssessed: string;
          securityPolicy: string;
          securityPolicyCount: number;
        }) => ({
          state: refined(obj.state),
          result: refined(obj.result),
          policyId: refined(obj.policyId),
          lastAssessed: refined(obj.lastAssessed),
          securityPolicy: refined(obj.securityPolicy),
          securityPolicyCount: obj.securityPolicyCount ?? 0,
        }),
      );
  };

  const refinedAdapter = (data, isUpperCase = false) => {
    if (data === undefined || data === null || data === '') {
      return '--';
    }
    if (isUpperCase) {
      return refined(data.toUpperCase());
    } else {
      return refined(data);
    }
  };

  const refinedNetworkAdapter = (data: any) => {
    const emptyAdapter = {
      enabled: false,
      hostname: '--',
      ipv4: { address: { ip: '--' }, enabled: false },
      ipv6: { address: { ip: '--' }, enabled: false },
      macAddress: '--',
      type: '--',
    };
    if (data === undefined || data === null || !data.adapters) {
      return [
        {
          ...emptyAdapter,
          name: 'eth0',
        },
        {
          ...emptyAdapter,
          name: 'wifi0',
        },
      ];
    } else {
      const adapters = data.adapters;
      const networkAdapters = [];

      adapters.forEach((adapter) => {
        if (adapter.name.startsWith('eth') || adapter.name.startsWith('wifi')) {
          networkAdapters.push({
            name: adapter.name,
            type: adapter.type,
            hostname: refinedAdapter(adapter.hostname ?? ''),
            macAddress: refinedAdapter(adapter.macAddress ?? '', true),
            ipv4: {
              address: {
                ip: objHasKeys(adapter, ['ipv4', 'address', 'ip'])
                  ? refinedAdapter(adapter.ipv4.address.ip)
                  : '--',
              },
            },
            ipv6: {
              address: {
                ip: objHasKeys(adapter, ['ipv6', 'address', 'ip'])
                  ? refinedAdapter(adapter.ipv6.address.ip, true)
                  : '--',
              },
            },
          });
        }
      });

      return networkAdapters;
    }
  };

  return {
    deviceId: refined(data.deviceId),
    groups: data.groups ?? [],
    connTypes: data.connTypes ?? [],
    proxyIds: refinedSolutionMetadataForProxy(data.solutionMetadata),
    lastUpdatedAt: refinedDate(data.lastUpdatedAt),
    type: refined(data.type),
    identity: {
      lastUpdatedAt: refined(data.identity?.lastUpdatedAt),
      serialNumber: refined(data.identity?.serialNumber),
      makeAndModel: {
        name: refined(data.identity?.makeAndModel?.name),
        number: refined(data.identity?.makeAndModel?.number),
        manufacturer: refined(data.identity?.makeAndModel?.manufacturer),
      },
      location: refined(data.identity?.location),
      friendlyName: refined(data.identity?.friendlyName),
      assetNumber: refined(data.identity?.assetNumber),
      firmwareVersion: refined(data.identity?.firmwareVersion),
    },
    status: {
      lastUpdatedAt: refined(data.status?.lastUpdatedAt),
      connectionState: data.status?.connectionState ?? 'offline',
      powerState: refined(data.status?.powerState),
    },
    software: {
      apps: refinedArray(data.software?.apps),
    },
    images:
      data.images?.map((obj) => ({
        url: refined(obj.url),
      })) ?? [],
    solutionMetadata: refinedSolutionMetadata(data.solutionMetadata),
    solutions: data.solutions ?? [],
    ownership: {
      accountId: refined(data.ownership?.accountId),
    },
    timestamp: {
      firstRegistrationTime: refinedDate(data.timestamp?.firstRegistrationTime),
      claimTime: refinedDate(data.timestamp?.claimTime),
      addedDate: refinedDate(data.timestamp?.firstRegistrationTime ?? data.timestamp?.claimTime),
    },
    network: { adapters: refinedNetworkAdapter(data.network) },
  };
};
