import React, { useEffect, useState } from 'react';
import rootReducer from 'src/store';
import packageInfo from '../package.json';
import CustomThemeProvider from 'src/contexts/CustomThemeProvider/CustomThemeProvider';
import { App } from 'src';
import { Provider } from 'react-redux';
import { TShellProps } from 'src/types/TShellProps';
import { CURRENT_STACK } from 'src/utils/constants';
import { configureStore } from '@reduxjs/toolkit';
import { ShellRootProvider } from 'src/contexts/ShellRoot';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ToastProvider, useToast, DirectionProvider } from '@veneer/core';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

declare const window: any;
declare const sessionStorage: any;

declare global {
  interface Window {
    Shell: TShellProps;
  }
}

export default function Root(props) {
  const { v1 } = window.Shell as TShellProps;
  const namespace = '@jarvis/react-ecp-device-details-overview';
  const [LDProvider, setLDProvider] = useState(null);
  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React);

  const getLDProvider = async () => {
    let clientID = '';

    if (CURRENT_STACK.LOCAL) clientID = '6232996cff5a0714a84243fc';
    else if (CURRENT_STACK.PIE) clientID = '6232996cff5a0714a84243ff';
    else if (CURRENT_STACK.STAGE) clientID = '6232996c7927321456ef8f7f';
    else {
      //production
      clientID = '6232996c7927321456ef8f82';
    }
    const Provider = await asyncWithLDProvider({
      clientSideID: clientID,
    });

    return Provider;
  };

  useEffect(() => {
    const setProvider = async () => {
      const Provider = await getLDProvider();

      setLDProvider(() => Provider);
    };
    setProvider();
  }, []);

  return LDProvider ? (
    <section id={namespace}>
      <DirectionProvider direction={directionValue}>
        <ToastProvider>
          <LDProvider>
            <ShellRootProvider
              id={packageInfo.name}
              ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
              stack={v1.app.getAuthStack()}
              localization={v1.localization}
              useToast={useToast}
              authProvider={v1.authProvider}
              navigation={v1.navigation}
              featureFlags={null}
              containerId={props.containerId}
              deviceId={props.deviceId}
              theme={v1.theme}
              isWex={props.isWex ?? false}
              mode={props.mode ?? 'light'}
              isFromApps={props.isFromApps ?? false}
              appUuid={props.appUuid ?? ''}
              routes={props.Routes}
              demoEnabled={props.demoEnabled}
              apiPath={props.apiPath}
              demoModeOptions={props.demoModeOptions}
              requestOptions={props.requestOptions}
            >
              <CustomThemeProvider>
                <Provider store={store}>
                  <App {...props} />
                </Provider>
              </CustomThemeProvider>
            </ShellRootProvider>
          </LDProvider>
        </ToastProvider>
      </DirectionProvider>
    </section>
  ) : (
    <></>
  );
}
